import Minze, { MinzeElement, Reactive, EventListeners , type Attrs} from "minze";
import { config } from "@/constants/config";
import { MainModule } from "@/modules/main";
import axios from 'redaxios';
const projectName = config.projectName;


export interface SscLoginTrigger {
  authStateListenerAttached: boolean;
  dataUserType: string;
  dataApi: string;
}

/**
  * This component is used to trigger login with legacy system when firebase is connected
  */
export class SscLoginTrigger extends MinzeElement {
  attrs: Attrs = [
    ["data-api", "/user/do_firebase_login"],
    ["data-user-type", "id"]
  ];
  async onStart() {
    this.attachAuthStateListener();
  }
  async onReady() {
    this.attachAuthStateListener();
  }

  async onDestroy() {
    MainModule.getInstance().getEE().off(
      `${projectName}:user_state_changed`,
      this.onAuthStateChanged.bind(this)
    );
  }

  async attachAuthStateListener() {
    console.log('attaching auth state listener')
    if (this.authStateListenerAttached) {
      return;
    }

    this.authStateListenerAttached = true;

    MainModule.getInstance().getEE().on(
      `${projectName}:user_state_changed`,
      this.onAuthStateChanged.bind(this)
    );

    this.onAuthStateChanged();
  }

  async onAuthStateChanged() {
    const mainModule = MainModule.getInstance();
    const auth = mainModule.getAuth();
    console.log('auth state changed', auth.isLoggedIn());

    if (!auth.isLoggedIn()) {
      return;
    }

    const token = MainModule.getInstance().getAuth()?.getUserData()?.accessToken;
    const pathname = `${window.location.pathname}`;


    if (token) {
      let isRightUserType = false;
      try {
         let isUserType = await axios.post(config.apis.userIs, {
          userType: "school_resource_id"
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
            }
        });
        isRightUserType = isUserType.data.success;
        
      } catch (e) {

        try {
         let isUserType = await axios.post(config.apis.userIs, {
          userType: "id"
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
            }
        });
        isRightUserType = isUserType.data.success;
          
        } catch (e) {
          
          auth.logout();
          mainModule.getEE().emit(`${projectName}:user_not_authorized_here_because_of_user_type`);
        }
      }

      if (!isRightUserType) {
        return;
      }
      try {
        const response = await axios.post(this.dataApi, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.data && response.data.redirect) {
          if (response.data.redirect.includes('bad_credentials')) {
            console.log('login_school returned "bad credentials"');
            auth.logout();
            mainModule.getEE().emit(`${projectName}:user_not_authorized_here_because_of_api`);
          } else {
            console.log('redirecting to', response.data.redirect);
            window.location.href = response.data.redirect;
          }
        }
      } catch (e) {
        auth.logout();
        mainModule.getEE().emit(`${projectName}:user_not_authorized_here_because_of_api`);
      }
    }

  }

  html = () => ``;
  css = () => ``;
}
